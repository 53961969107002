
import { DataGridControl } from './DataGridControl.ts';

declare module './DataGridControl.ts' {
    interface DataGridControl {
        layoutManager: LayoutManager;
    }
}

Object.defineProperties(DataGridControl.prototype, {
    'layoutManager': {
        get() {
            if (this.dataObject == null) {
                return null;
            } else {
                if (this._layoutManager == null) {
                    this._layoutManager = new LayoutManager(this);
                }
                return this._layoutManager;
            }
        }
    },
});

/**
 * Helper class to initialize on-demand modules from layouts.
 * Used to startup extensinos such as summaryData and nodeData
 */
export class LayoutManager {
    private _dataGridControl: DataGridControl;

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    initialize() {
        if (this._dataGridControl.dataObject == null) { return; }
        this.initExtensionsFromLayout();
        return this._dataGridControl.dataObject.on('LayoutApplied', () => {
            this.initExtensionsFromLayout();
        })
    }

    initExtensionsFromLayout() {
        const dataObject = this._dataGridControl.dataObject;
        if (dataObject == null) { return; }
        if (!dataObject.layoutManager?.hasActiveLayout) { return; }
        const activeLayout = dataObject.layoutManager.activeLayout!;

        if (activeLayout.layoutRecord['summaryData'] != null) {
            import('o365-data-summary').then(() => {
                this._dataGridControl.summaryData;
            });
        }
    }

    /**
     * @param pId Layout ID
     */
    populateExportConfigDiferentLayout(columnsBase: BaselineColumn[], layout: any, properties: any) {

        const arr = [];
        const parseToString = (pValue) => {
            if (typeof pValue == 'number') {
                return `${pValue}`;
            } else {
                return pValue;
            }
        }
        const rearangeColumns = (currentColumns: any, newLayoutInp: any) => {
            let columnsTemp = [...currentColumns];
            if (Object.keys(newLayoutInp).length == 0) { return columnsTemp }
            var newLayout = Object.entries(newLayoutInp.columns).map(([name, value]) => ({
                name,
                value
            }));
            newLayout.forEach(({ name, value: { order } }) => {
                if (order != undefined) {
                    const columnIndex = columnsTemp.findIndex(col => col.name === name);
                    if (columnIndex !== -1) {
                        const [column] = columnsTemp.splice(columnIndex, 1);
                        // const newPosition = order - 1;
                        const newPosition = order - 2;
                        columnsTemp.splice(newPosition, 0, column);
                    }
                }
            });
            return columnsTemp


        }
        // const getColId = ()
        const parseDataType = (pType: string) => {
            switch (pType) {
                case 'bool':
                    return 'bit';
                case 'date':
                    return 'date';
                case 'datetime':
                    return 'datetime';
                case 'number':
                case 'numeric':
                    return 'number';
                default:
                    return 'string';
            }
        }

        for (const baselineCol of columnsBase) {
            let layoutCol = undefined;
            if (Object.keys(layout).length > 0) {
                layoutCol = layout?.columns[baselineCol.colId]; // get col from layout
            }
            if (baselineCol.colId.startsWith('Property.')) {
                const property = properties.find(x => x.Name == baselineCol.colId.split('Property.')[1]); // get from properties array
                if (property == undefined) { continue; }
                const systemColumn = this._dataGridControl.dataColumns.columns[0];
                const dataType = parseDataType(property.DataType);
                arr.push(
                    {
                        name: `Property.${property.Name}`,
                        field: `Property.${property.Name}`,
                        colId: `Property.${property.Name}`,
                        headerName: property.Caption ?? property.Name,
                        caption: property.Caption ?? property.Name,
                        width: layoutCol?.width ?? systemColumn.getDefaultWidthForColumn(dataType),
                        dataType: dataType,
                        maxLength: undefined,
                        format: systemColumn.getDefaultFormatForColumn(dataType),
                        // shown: !layoutCol?.hide,
                        hide: !!layoutCol?.hide,
                        get shown(): boolean {
                            return !this.hide;
                        },
                        set shown(value) {
                            this.hide = !value;
                        },
                        link: undefined,
                        summaryAggregate: undefined,
                        parentGroupId: undefined,
                        required: undefined
                    }
                )
            } else {
                const colRef = this._dataGridControl.dataColumns.getColumn(baselineCol.colId);
                if (colRef == null) { continue; }
                arr.push(
                    {
                        name: colRef.name,
                        field: colRef.field,
                        colId: colRef.colId,
                        headerName: parseToString(colRef.headerName),
                        caption: colRef.headerName ? parseToString(colRef.headerName) : parseToString(this._dataGridControl.dataObject!.fields[colRef.field]?.caption),
                        width: layoutCol?.width ?? baselineCol.width,
                        dataType: this._dataGridControl.dataObject!.fields[colRef.field]?.type,
                        maxLength: this._dataGridControl.dataObject!.fields[colRef.field]?.maxLength,
                        format: colRef.format,
                        // shown: colRef.required || (layoutCol === undefined ? !baselineCol.hide : !layoutCol?.hide),
                        // hide: !!layoutCol?.hide ?? !!baselineCol.hide,
                        hide: layoutCol === undefined ? baselineCol.hide : layoutCol?.hide,
                        get shown(): boolean {
                            return colRef.required || !this.hide;
                        },
                        set shown(value) {
                            this.hide = !value;
                        },
                        link: colRef.cellRenderer === "OLink" ? colRef.cellRendererParams : null,
                        summaryAggregate: colRef.summaryAggregate,
                        parentGroupId: colRef.parentGroupId,
                        required: colRef.required
                    }
                )

            }
            // }

        }
        // reorder based on layout
        return rearangeColumns(arr, layout);
    }
    async getColumnLayout(pId: number) {
        if (this._dataGridControl.dataObject == null) { return undefined; }
        const { getOrCreateProcedure, configurableRegister } = await import('o365-modules');

        const manageProc = getOrCreateProcedure({
            id: `procManageLayouts_${this._dataGridControl.id}`,
            procedureName: 'sstp_O365_ManageLayouts'
        });
        const layoutResponse = await manageProc.execute({
            Action: 'retrieve',
            App_ID: this._dataGridControl.dataObject.appId,
            DataObject_ID: this._dataGridControl.dataObject.id,
            Register_ID: configurableRegister.id
        });
        if (layoutResponse == null || !layoutResponse.Table?.length) { return undefined; }
        let layoutRecord = null;
        layoutResponse.Table.forEach(x => {
            if (x.ID == pId) {
                layoutRecord = x;
            }
        })
        if (layoutRecord == null) { return undefined }
        const layout = JSON.parse(layoutRecord.Layout) as Record<string, any>;
        const baseline = this._dataGridControl.dataColumns.getBaseColumnsLayout();

        // clean baseline - remove all property columns from baseline
        Object.keys(baseline).forEach(key => {
            if (key.startsWith('Property.')) {
                delete baseline[key]; 
            }
        });
        const baselineEntries = Object.entries(baseline);
        const baselineArray = baselineEntries.map(([colId, colDef]) => {
            // return { colId, colDef };
            return { ...colDef, colId: colId };
        });
        baselineArray.sort((a, b) => a.order! - b.order!);
        const lastOrder = baselineArray.reduce((max, col) => {
            return max > col.order ? col.order : max;
        }, 0);
        // loop through selected propeties, append to baselineArray

        let properties = undefined
        if (layout.properties && layout.properties.selected?.length) {
            const { getPropertiesDefinitions } = await import('o365-data-properties');
            properties = await getPropertiesDefinitions(layout.properties.selected.map(prop => prop.ID));
            properties.forEach((x, i) => {
                baselineArray.push({
                    order: lastOrder + i,
                    width: 200,
                    colId: 'Property.' + x.Name,
                    hide: false,
                    hideFromChooser: undefined,
                    pinned: null
                });
            })
        }
        return this.populateExportConfigDiferentLayout(baselineArray, layout, properties);
    }
}

type BaselineColumn = {
    colId: string;
    pinned?: "left" | "right" | null | undefined;
    width?: number | undefined;
    order?: number | undefined;
    hide?: boolean | undefined;
    hideFromChooser?: boolean | undefined;
};